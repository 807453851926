// extracted by mini-css-extract-plugin
export var eMarketplaceDevBenefitsSection = "C_fN";
export var eMarketplaceDevBusinessSection = "C_fH";
export var eMarketplaceDevCardBrdrIconSection = "C_fF";
export var eMarketplaceDevCaseSection = "C_fQ";
export var eMarketplaceDevClientQuotesSection = "C_fS";
export var eMarketplaceDevExpertiseSection = "C_fL";
export var eMarketplaceDevFeaturesSection = "C_fG";
export var eMarketplaceDevIndustriesSection = "C_fJ";
export var eMarketplaceDevOurAchievementsSection = "C_fR";
export var eMarketplaceDevProcessSection = "C_fK";
export var eMarketplaceDevProjLogoSection = "C_fP";
export var eMarketplaceDevServicesIconSection = "C_fD";
export var eMarketplaceDevTechStackSection = "C_fM";